<script lang="ts">
  import { loggedInStore } from '@stores/logged-in-store'
</script>

<svelte:head>
  <title>Hirundo Management - Home</title>
  <meta name="description" content="Hirundo Management: This is the UI to manage Hirundo tools" />
</svelte:head>

<div class="mx-auto max-w-2xl lg:text-center">
  <section>
    <h2 class="h2 mb-4 mt-2 font-medium tracking-tight">
      {$loggedInStore.fullName}, welcome to Hirundo!
    </h2>
    <p>Here is the place to manage your organizations & users and create API keys</p>
  </section>
</div>
